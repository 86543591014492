<template>
  <v-card :loading="loading" rounded>
    <v-card-title>Настройки ОПД</v-card-title>
    <v-card-text v-if="pools">
      <v-autocomplete clearable item-value="id" v-model="project_pools_id" :item-text="(item) => item.name + ' (' + item.start_at + ' - ' + item.end_at + ') '" :items="pools" placeholder="Пул"></v-autocomplete>
      <v-autocomplete clearable item-value="id" v-model="tutor_id" :items="$store.state.timetables.employees"
                  item-text="name" placeholder="Наставник"></v-autocomplete>
      <v-btn :loading="loading" @click="save" outlined color="primary" rounded dark>Сохранить</v-btn>
    </v-card-text>

  </v-card>
</template>

<script>
export default {
  name: "ProjectOpdModalSettings",
  computed: {
    project(){
      return this.$store.state.projects.currentProject;
    },
    pools(){
      return this.$store.state.dictionaries.project_pools;
    }
  },
  methods: {
    save(){
      this.loading = true;
      this.$store.dispatch('updateProject', {id: this.project.id, project_pools_id: this.project_pools_id, tutor_id: this.tutor_id}).then(() => {
        this.loading = false;
      })
    }
  },
  data() {
    return {
      project_pools_id: null,
      tutor_id: null,
      loading: true
    }
  },
  mounted() {
    Promise.all([this.$store.dispatch('getProjectPools'),
    this.$store.dispatch('getEmployees')]).then(() => {
      this.loading = false;
    })

    this.project_pools_id = this.project.pool.id;
    this.tutor_id = this.project.tutor.id;
  }
}
</script>

<style scoped>

</style>
