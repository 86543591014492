<template>
  <div>
    <CardUI>
      <template v-slot:header>
        Отклонить проект
      </template>
      <template v-slot:body>
        <TextAreaUI v-model="declineReason" placeholder="Причина"/>
        <ButtonUI :loading="loading" @click="decline">Отклонить</ButtonUI>
      </template>
    </CardUI>
  </div>
</template>

<script>
import CardUI from "@/components/UI/CardUI";
import TextAreaUI from "@/components/UI/TextAreaUI";
import ButtonUI from "@/components/UI/ButtonUI";

export default {
  name: "DeclineProjectDialog",
  components: {ButtonUI, CardUI, TextAreaUI},
  data() {
    return {
      declineReason: '',
      loading: false
    }
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    }
  },
  methods: {
    decline() {
      this.loading = true;
      this.$store.dispatch('declineProject', {id: this.project.id, decline_reason: this.declineReason}).then(() => {
        this.$store.dispatch('getProjects');
        this.loading = false;
        this.$emit('close');
        this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id});
        this.$store.dispatch('getProjectsByUser', {user_id: this.$store.state.user.currentUser.id})
      })
    }
  }
}
</script>

<style scoped>

</style>
