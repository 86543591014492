<template>
  <div class="navigation">
    <div :style="`color: ${$store.state.university.university.color}`">

      <router-link v-if="project.permissions.can_see_chat && project.pool" :to="{name: 'project.exams'}">
        <v-badge color="red" content="" dot class="mt-3">
          Аттестация
        </v-badge>
      </router-link>
      <hr color="#e7e7e7" noshade size="1"/>
      <router-link v-if="project.permissions.can_see_info" :to="{name: 'project.info'}">
        Информация о проекте
      </router-link>
      <router-link v-if="project.permissions.can_see_info" :to="{name: 'project.passport'}">
        Паспорт проекта
      </router-link>
      <router-link v-if="project.permissions.can_see_chat" :to="{name: 'project.costs'}">
        <v-badge color="primary" content="new">
          Смета
        </v-badge>
      </router-link>
      <router-link v-if="project.permissions.can_see_chat" :to="{name: 'project.calendar'}">
        Календарь
      </router-link>
      <router-link v-if="project.permissions.can_see_tasks" :to="{name: 'project.trello'}">
        Доска задач
      </router-link>

      <hr color="#e7e7e7" noshade size="1"/>
      <router-link v-if="project.permissions.can_see_users" :to="{name: 'project.participants'}">
        Участники
        <v-badge :color="$store.state.university.university.color" inline :content="project.users.length"/>
      </router-link>
      <router-link v-if="project.permissions.can_edit_roles" :to="{name: 'project.roles'}">
        Роли проекта
        <v-badge :color="$store.state.university.university.color" inline :content="project.roles.length"/>
      </router-link>
      <router-link v-if="project.permissions.can_see_chat" :to="{name: 'project.chat'}">
        Чат
      </router-link>

      <router-link v-if="project.permissions.can_see_info" :to="{name: 'project.feed'}">
        Новостная лента
      </router-link>
      <router-link v-if="project.permissions.can_see_files" :to="{name: 'project.files'}">
        Файлы проекта
      </router-link>

      <!--      <a :href="`https://project${project.id}.projed.ru/`">-->
      <!--        Лендинг проекта-->
      <!--        <v-badge  class="ml-2" :color="$store.state.university.university.color" inline content="new"/>-->
      <!--      </a>-->

    </div>

  </div>
</template>

<script>
export default {
  name: "ProjectItemMenu",
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    }
  },
}
</script>

<style scoped>
.navigation {
  background: white;
  border-radius: 20px;
  overflow: hidden;
}

.navigation a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 10px 20px;
  display: block;
}

.navigation a:hover {
  background: #fafafa;
}
</style>
