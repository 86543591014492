<template>
  <div>
    <v-container style="max-width: 980px">

      <div v-if="project">
        <v-dialog v-model="declineDialog" max-width="500">
          <DeclineProjectDialog @close="declineDialog = false"/>
        </v-dialog>
        <v-col cols="12" md="12" lg="12" xs="12">
          <BackendBanner v-if="!project.permissions.can_see_files" code="opd_banner"/>
        </v-col>
        <div style="background: white; padding: 20px; border-radius: 20px; "
             class="text-center text-md-left mb-4 d-flex flex-md-row flex-column align-center justify-center justify-md-start">
          <v-avatar class="mr-0 mr-md-4 mb-5 mb-md-0" size="100">
            <v-img :src="project.logotype_url"/>
          </v-avatar>
          <div style="max-width: 100%;">
            <span>
            {{ project.type.name.toLowerCase() }} проект
         </span>
            <h2 class="max-2-lines">
              {{ project.title }}
            </h2>
            <div class="d-flex flex-md-row flex-column align-center">
              <UserLinkComponent class="mr-0 mr-md-2 mt-2" :user="project.responsible_user"/>
              <v-tooltip v-if="project.pool" bottom>

                <span v-if="project.tutor">
                    {{ project.tutor.name }}
                </span>
                <span v-else>
                  Нет наставника
                </span>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip small v-bind="attrs"
                          v-on="on" class="mt-2 mr-0 mr-md-2" color="green" dark v-if="project.pool">
                    {{ project.pool.name }}
                  </v-chip>
                </template>
              </v-tooltip>
              <v-chip rounded small class="mt-2" color="warning" dark
                      v-if="!project.approved && !project.decline_reason">
                На модерации
              </v-chip>
              <v-chip rounded small class="mt-2" color="error" dark v-if="!project.approved && project.decline_reason">
                Отклонен
              </v-chip>
              <v-chip dark color="grey" class="d-block mt-2 mr-2 mr-md-0" v-if="project.project_archive_reasons_id > 0">
                Проект в архиве
              </v-chip>

            </div>
          </div>
          <v-spacer/>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mt-5 ml-3" v-on="on" outlined rounded color="primary">
                <v-icon left>
                  mdi-dots-vertical
                </v-icon>
                Действия
              </v-btn>
            </template>
            <v-list>
              <v-subheader>Действия с проектом</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                    v-if="project.permissions.can_leave"
                    @click="$store.dispatch('declineUserOnProject', {id: project.id, user_id: $store.state.user.currentUser.id})">
                  <v-list-item-content>
                    <v-list-item-title>Выйти из проекта</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="red">mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-dialog v-model="applicationDialog" max-width="500">
                  <template v-slot:activator="{ on }">
                    <v-list-item v-if="project.permissions.can_join" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>Вступить в проект</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon color="green">mdi-login-variant</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Кем бы вы хотели стать в проекте?</v-card-title>
                    <v-card-text>
                      <v-alert type="error" dense v-if="!project.roles.filter(role => !isFullRole(role)).length">
                        Нет свободных вакансий! Попросите
                        {{ project.responsible_user.name }} добавить еще вакансии, если хотите вступить в проект.
                      </v-alert>
                      <v-autocomplete v-else v-model="application.project_role_id" :item-disabled="isFullRole"
                                      :items="project.roles" auto-select-first
                                      autocomplete="off" item-text="name" item-value="id"
                                      label="Выберите вакансию" no-data-text="Такие нам не нужны"/>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="applicationDialog = false">Отменить</v-btn>
                      <v-btn :loading="application.loading" :disabled="!application.project_role_id"
                             color="blue darken-2"
                             text @click="enterToProject">Подать заявку
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-list-item
                    v-if="$store.state.projects.currentUserStatusInProject && $store.state.projects.currentUserStatusInProject.approved == 0"
                    @click="$store.dispatch('declineUserOnProject', {id: project.id, user_id: $store.state.user.currentUser.id})">
                  <v-list-item-content>
                    <v-list-item-title>Отменить заявку</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-subheader>Администрирование</v-subheader>

                <v-list-item
                    v-if="project.permissions.can_approve"
                    @click="approve(project)">
                  <v-list-item-content>
                    <v-list-item-title>Одобрить проект</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="green">mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item
                    v-if="project.permissions.can_decline"
                    @click="decline(project)">
                  <v-list-item-content>
                    <v-list-item-title>Отклонить проект</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="red">mdi-cancel</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                    v-if="project.permissions.can_send_to_moderation"
                    @click="toModeration(project)">
                  <v-list-item-content>
                    <v-list-item-title>Подать на модерацию</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="green">mdi-check</v-icon>
                  </v-list-item-icon>
                </v-list-item>


                <v-list-item v-if="project.permissions.can_delete" @click="remove(project)">
                  <v-list-item-content>
                    <v-list-item-title>Удалить</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item v-if="$store.getters.checkPermission('can_manage_projects')" @click="opdDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>Настроить ОПД</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="green">mdi-settings</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-dialog
                    v-if="$store.state.projects.currentProject.project_archive_reasons_id  === null"
                    v-model="archiveDialog" max-width="500" persistent>
                  <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="$store.getters.checkPermission('can_archive_projects')"
                        v-on="on" :disabled="$store.state.projects.currentProject.project_archive_reasons_id > 0">
                      <v-list-item-content>
                        <v-list-item-title>В архив</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-archive</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Почему вы хотите его архивировать?</v-card-title>
                    <v-card-text>
                      <v-autocomplete v-model="project_archive_reasons_id"
                                      :items="$store.state.projects.archiveReasons" auto-select-first

                                      autocomplete="off"
                                      item-text="reason" item-value="id" label="Причина архивации"
                                      no-data-text="Такой причины нет"/>

                      <v-switch label="Создать копию без участников и задач" v-model="archiveClone"/>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="archiveDialog = false">Отменить</v-btn>
                      <v-btn :loading="loading" color="blue darken-2" text @click="archive">
                        Архивировать
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


              </v-list-item-group>

            </v-list>
          </v-menu>


          <v-dialog width="500" v-model="opdDialog">
            <ProjectOpdModalSettings/>
          </v-dialog>

        </div>
        <v-sheet v-if="project.decline_reason" style="padding: 20px; border-radius: 20px; border-top: 10px #ff5252 solid;">
          <h4>Проект отклонен по причине:</h4>
          <div class="mt-2">
            {{ project.decline_reason }}
          </div>
          <div v-if="project.permissions.can_send_to_moderation" class="mt-2">
            <v-btn @click="toModeration(project)" rounded color="primary" small outlined>Подать повторно на модерацию</v-btn>
          </div>
        </v-sheet>
        <div v-if="project && !project.project_archive_reasons_id">
          <v-row class="mt-4">
            <v-col lg="8" md="8" cols="12" order="1" order-xs="0" order-md="0">
              <div>
                <div ref="before_router"></div>
                <keep-alive>
                  <router-view v-show="project && !project.project_archive_reasons_id"/>
                </keep-alive>
              </div>
            </v-col>
            <v-col lg="4">
              <ProjectItemMenu style="position: sticky; top: 75px;"/>
            </v-col>
          </v-row>

        </div>


      </div>
      <div v-else>
        <v-skeleton-loader
            class="mx-auto mt-2"
            type="list-item-avatar-three-line"
        ></v-skeleton-loader>

        <v-skeleton-loader
            class="mx-auto mt-2"
            type="article"
        ></v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>

import analytics from "@/api/analytics.js";
import BackendBanner from "@/components/Utility/BackendBanner";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import ProjectOpdModalSettings from "@/components/Projects/ProjectsItem/ProjectOpdModalSettings";
import ProjectItemMenu from "@/components/Projects/ProjectsItem/ProjectItemMenu";
import DeclineProjectDialog from "@/components/Projects/Utils/DeclineProjectDialog";

export default {
  name: "ProjectsItemComponent",
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    }
  },
  components: {
    DeclineProjectDialog,
    ProjectItemMenu,
    BackendBanner, UserLinkComponent, ProjectOpdModalSettings
  },
  data() {
    return {
      loading: false,
      applicationDialog: false,
      opdDialog: false,
      archiveDialog: false,
      project_archive_reasons_id: null,
      archiveClone: false,
      application: {
        project_role_id: null,
        admin: false,
        loading: false
      },
      declineDialog: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setCurrentProject', null);
    next();
  },
  watch: {
    '$route'() {
      if (this.$refs.before_router.getBoundingClientRect().top && window.scrollY) {
        setTimeout(() => {
          window.scrollTo({
            top: this.$refs.before_router.getBoundingClientRect().top + window.scrollY - 56,
            behavior: "smooth"
          });
        }, 1);

      }

    }
  },
  methods: {
    enterToProject() {
      this.application.loading = true
      this.$store.dispatch('enterToProject', {id: this.project.id, ...this.application}).then(() => {
        this.application.loading = false
        this.applicationDialog = false
        window.jsConfetti.addConfetti();
        this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id});
      })
    },

    isFullRole(role) {
      return this.$store.getters.getParticipantsByProjectRole(role.id).length >= parseInt(role.quota)
    },

    archive() {
      this.loading = true;
      this.$store.dispatch('archiveProject', {
        id: this.$store.state.projects.currentProject.id,
        project_archive_reasons_id: this.project_archive_reasons_id,
        clone: this.archiveClone
      }).then(() => {
        this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id}).then(() => {
          this.archiveDialog = false;
          this.loading = false;
        })

      }).finally(() => {
        this.loading = false;
      })
    },

    approve(project) {
      this.loading = true;
      if (confirm('Вы действительно хотите одобрить?')) {
        this.$store.dispatch('approveProject', {id: project.id}).then(() => {
          this.$store.dispatch('getProjects');
          this.loading = false;
          window.jsConfetti.addConfetti({emojis: ['💥']});
          this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id});
          this.$store.dispatch('getProjectsByUser', {user_id: this.$store.state.user.currentUser.id})
        })
      }
    },

    decline() {
      this.declineDialog = true;
    },

    toModeration() {
      if (confirm('Вы действительно хотите отправить на модерацию?')) {
        this.$store.dispatch('toModeration', {id: this.project.id}).then(() => {
          this.$store.dispatch('getProjects');
          this.loading = false;
          window.jsConfetti.addConfetti({emojis: ['💥']});
          this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id});
          this.$store.dispatch('getProjectsByUser', {user_id: this.$store.state.user.currentUser.id})
        })
      }
    },
    remove(project) {
      if (confirm('Вы действительно хотите удалить проект?')) {
        this.loading = true;
        this.$store.dispatch('removeProject', {id: project.id}).then(() => {
          this.loading = false;
          this.$store.dispatch('getProjects');
          this.$store.dispatch('getProjectsByUser', {user_id: this.$store.state.user.currentUser.id})
          this.$router.push('/projects/')
        })

      }
    },
  },
  mounted() {
    if (!this.$store.state.user.currentUser.id) return this.$router.push('/auth')
    Promise.all([
      this.$store.dispatch('getEmployees'),
      this.$store.dispatch('getArchiveReasons'),
      this.$store.dispatch('getProjectPosts', {id: this.$route.params.project_id}),
      this.$store.dispatch('getProjectTypes'),
      this.$store.dispatch('getProjects'),
      this.$store.dispatch('getCurrentUserStatusInProject', {id: this.$route.params.project_id}),
      this.$store.dispatch('getProjectParticipants', {id: this.$route.params.project_id}),
      this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id})]).then(() => {
      this.loading = false;
      document.title = 'АИС | ' + this.$store.state.projects.currentProject.title;

      analytics.openProjectPageEvent(this.$route.params.project_id, this.$store.state.user.currentUser.id);
      // this.$emit('changeTitle', this.$store.state.projects.currentProject.title)
    })
  }
}
</script>

<style scoped>
.max-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
</style>
